<template>
  <v-app v-if="false">
    <v-app-bar app dense flat :dark="!isDark" :color="isDark ? 'white':'transparent'" :class="officialWebsite.showReturnTip ? 'h5-head-box':''" style="z-index: 12;">
      <div class="h5-app-bar">
        <v-img @click="$router.push('/h5Number/home')" max-width="200" height="120%" alt="logo" contain position="left center" style="margin-left: 10px;" :src="isDark ? logoLan:logoBai" />
        <v-img @click.stop="drawer = !drawer" max-width="16" contain :src="drawer ? menuIconClose : (isDark ? menuIconHei : menuIconBai)"></v-img>
      </div>
      <div v-show="drawer" @click.stop class="app-bar-menu">
        <v-list light>
          <v-list-item to="/h5Number/home" @click.stop="drawer = !drawer">
            <v-list-item-title class="menu-title">首页</v-list-item-title>
          </v-list-item>

          <v-list-group v-model="menuGroupProducts">
            <template v-slot:appendIcon>
              <span :class="menuGroupProducts ? 'rotate-180':'rotate-0'">
                <v-img max-width="15" contain :src="menuArrowB"></v-img>
              </span>
            </template>
            <template v-slot:activator>
              <v-list-item-title class="menu-title">产品</v-list-item-title>
            </template>

            <v-list-item class="ml-12" v-for="(info, i) in products" :key="i" :to="info.router" @click.stop="drawer = !drawer">
              <v-list-item-title v-text="info.title" class="font-size-14 color-333"></v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-group v-model="menuGroupSolutions">
            <template v-slot:appendIcon>
              <span :class="menuGroupSolutions ? 'rotate-180':'rotate-0'">
                <v-img max-width="15" contain :src="menuArrowB"></v-img>
              </span>
            </template>
            <template v-slot:activator>
              <v-list-item-title class="menu-title">解决方案</v-list-item-title>
            </template>

            <v-list-item class="ml-12" v-for="(info, i) in solutions" :key="i" :to="info.router" @click.stop="drawer = !drawer">
              <v-list-item-title v-text="info.title" class="font-size-14 color-333"></v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item href="https://meta.ophyer.com/h5/Home" @click.stop="drawer = !drawer">
            <v-list-item-title class="menu-title">飞天元宇宙</v-list-item-title>
          </v-list-item>
          <v-list-item href="https://www.flowingcloud.com" @click.stop="drawer = !drawer">
            <v-list-item-title class="menu-title">投资者关系</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item to="/h5Number/education" @click.stop="drawer = !drawer">
            <v-list-item-title class="menu-title">数字化教育</v-list-item-title>
          </v-list-item> -->
          <!--<v-list-item to="/h5/case" @click.stop="drawer = !drawer">-->
          <!--  <v-list-item-title class="menu-title">合作案例</v-list-item-title>-->
          <!--</v-list-item>-->
          <v-list-item to="/h5Number/about" @click.stop="drawer = !drawer">
            <v-list-item-title class="menu-title">关于我们</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-app-bar>
    <v-main class="py-0" style="overflow-y: auto">
      <router-view></router-view>
    </v-main>

    <!-- 底部 -->
    <H5OfficialWebsiteFooter />
  </v-app>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'H5',
  components: {
    H5OfficialWebsiteFooter: () => import('@/components/common/h5OfficialWebsiteFooter.vue'),
  },
  data: () => ({
    drawer: false,
    products: [
      {
        title: 'FT引擎',
        router: '/h5Number/numberProductsH5/Fte'
      },
      {
        title: 'SaaS',
        router: '/h5Number/numberProductsH5/SaasUGC'
      },
      {
        title: '硬件产品',
        router: '/h5Number/numberProductsH5/Hardware'
      },
      {
        title: '数字人',
        router: '/h5Number/DigitalMan'
      },
      {
        title: 'AIGC',
        router: '/h5Number/aigc'
      },
      /* {
        title: '数字资产',
        router: '/h5Number/numberProductsH5/DigitalAssets'
      } */
    ],
    solutions: [
      {
        title: '娱乐',
        router: '/h5Number/numberSolutionsH5/Entertainment'
      },
      {
        title: '文博和旅游',
        router: '/h5Number/numberSolutionsH5/CulturalTourism'
      },
      {
        title: '新零售',
        router: '/h5Number/numberSolutionsH5/NewRetail'
      },
      {
        title: '房地产',
        router: '/h5Number/numberSolutionsH5/RealEstate'
      },
      {
        title: '工业制造',
        router: '/h5Number/numberSolutionsH5/IndustrialManufacturing'
      },
      {
        title: '职业培训',
        router: '/h5Number/numberSolutionsH5/VocationalTraining'
      },
      {
        title: '元宇宙解决方案',
        router: '/h5Number/numberSolutionsH5/MetaverseSolution'
      },
      {
        title: '媒体运营',
        router: '/h5Number/numberSolutionsH5/MediaOperation'
      }
    ],
    logoHei: require('@/assets/login/logo_hei.png'),
    logoBai: require('@/assets/login/logo_bai_bak.png'), //飞天云动logo
    logoLan: require('@/assets/login/logo_lan_bak.png'), //飞天云动logo
    menuIconClose: require('../../../public/img/icons/menu/closeMenuH5.png'),
    menuIconHei: require('../../../public/img/icons/menu/menuHei.png'),
    menuIconBai: require('../../../public/img/icons/menu/menuBai.png'),
    menuArrowB: require('../../../public/img/icons/menu/arrowB.png'),
    menuGroupProducts: true,
    menuGroupSolutions: false,
    hiddenHeaderMenu: ['education','DigitalMan', 'aigc'],
    pathInfo: true

  }),
  watch: {
    $route(to) {
      const menuInfo = to.path.split('/')
      this.currentMenu = '/' + menuInfo[3]
      this.pathInfo = this.hiddenHeaderMenu.indexOf(window.location.pathname.split('/')[2]) === -1
    }
  },
  computed: {
    ...mapState(['officialWebsite']),
    isDark() {
      if (this.officialWebsite.showReturnTip || this.drawer || this.$route.path === '/h5/CaseDetail') {
        return true
      }
      return false
    }
    // footerHeight () {
    //   switch (this.$vuetify.breakpoint.name){
    //     case 'xs': return 90
    //     case 'sm': return 65
    //     case 'md': return 50
    //     case 'lg': return 50
    //     case 'xl': return 50
    //     default: return 70
    //   }
    // }
  },
  mounted() {
    let _this = this
    this.pathInfo = this.hiddenHeaderMenu.indexOf(window.location.pathname.split('/')[2]) === -1

    document.addEventListener('click', () => {
      _this.drawer = false
    })
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.h5-app-bar {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  .bar-icon::after {
    display: none;
  }
}
.app-bar-menu {
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  .menu-title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
  }
}

</style>
